<!--
* @Description: 
* @Author: sunchunchun
* @CreateDate: 2024/07/17 16:04:45
* @FileName: editPhoneLogin.vue
!-->
<template>
    <div class="phone-box">
        <div class="cardForm" style="padding: 10px 20px;">
            <div class="title">修改手机号</div>
            <van-form ref="formCode" style="margin-bottom: 90px;">
                <van-field
                    class="phone-input"
                    style="font-size:16px"
                    v-model="form.oldPhone"
                    disabled
                    name="oldPhone"
                    center
                    clearable
                    type="tel"
                    label="旧手机号"
                    maxlength="11"
                />
                <van-field
                    class="phone-input"
                    style="font-size:16px"
                    v-model="form.phone"
                    name="phone"
                    center
                    clearable
                    type="tel"
                    label="新手机号"
                    maxlength="11"
                    placeholder="请输入"
                />
                <van-field
                    class="phone-input"
                    style="font-size:16px"
                    v-model="form.code"
                    center
                    clearable
                    label="短信验证码"
                    maxlength="6"
                    placeholder="请输入"
                >
                    <template #button>
                        <van-button
                            @click="onPullCode(180)"
                            size="small"
                            :disabled="num!=0"
                            type="info"
                            class="btn-s"
                            style="font-size:15px;height: auto;display: flex">
                            {{ num == 0 ? codeText : num + '秒后重试' }} 
                        </van-button>
                    </template>
                </van-field>
            </van-form>
            <div style="text-align: center;">
                <van-button
                    :loading="loading"
                    class="ant-btn btn-stl-1"
                    style="font-size: 16px;"
                    round 
                    type="info"
                    native-type="submit"
                    @click="onNext"
                >
                    确定
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getPhoneNum } from "@/api/user/index"
import storage from 'store'
import { updatePhoneByUserId } from "@/api/user/updataPhone"
export default {
    data () {
        return {
            loading: false,
            code: '',
            form: { oldPhone: '', code: '', phone: '' },
            num: 0,
            codeText: '获取验证码', // 验证码文本

        }
    },
    created() {
        this.form.oldPhone = this.$route.query.phone
    },
    methods: {
        // 确定
        onNext() {
            if (this.form.code && this.form.phone) {
                updatePhoneByUserId({befor: this.form.oldPhone, after: this.form.phone, userId: storage.get('phoneNoLoginUserId'), code: this.form.code}).then(res => {
                    if (res.code == 200) {
                        this.$toast.success('修改成功')
                        setTimeout(_ => {
                            this.$router.push('/')
                            storage.set('phoneNoLoginPhone', '')
                            storage.set('phoneNoLoginUserId', '')
                        }, 1000)
                    } else {
                        this.$toast(res.msg)
                    }
                })
            } else {
                this.$toast('请输入手机号及验证码')
            }
        },
        // 发送验证码
        onPullCode(num) {
            // 首次进入调用获取验证码接口
            if (this.form.phone && this.form.phone != '') {
                this.num = num
                this.getNum(this.num)
                let time = setInterval(() => {
                    this.num -= 1
                    if (this.num == 0) {
                        // 读秒结束清除计时器
                        this.noFlag = true
                        clearInterval(time)
                        return false
                    }
                }, 1000);
            } else {
                this.$toast('请输入新手机号')
            }
        },
        // 获取验证码
        getNum(num) {
            getPhoneNum({phone: this.form.phone}).then(res => {
                this.$store.dispatch('comEditPhoneFun', num)
                this.code = res.data
            })
        },
    }
}
</script>

<style lang='scss' scoped>
.phone-box{
    min-height: 100vh;
    padding: 30px 30px 20px 30px;
}
.title{
    color: #bea588;
    margin-top: 20%;
    font-size: 60px;
    text-align: center;
    margin-bottom: 100px;
}
.tel-phone{
    width: 100%;
    float: left;
    margin-left: 30px;
    color: #959595;
}
.btn-s{
    background: none;
    color: #BEA588;
    border: none;
    height: 10px;
}
.ant-btn{
    margin-top: 100px;
    padding: 0 200px;
}


/deep/
.phone-input{
    background: none;
    border: 1px solid #e6d2bc;
    border-radius: 10px;
    margin-top: 20px;
}
</style>