import request from "../../../request/index"

/**
 * 个人
 * 获取修改手机号的用户ID
 */
export function getUserIdP (data) {
    return request({
        url: '/open/user/getUserIdP',
        method: 'get',
        params: data,
    })
}

/**
 * 企业
 * 获取修改手机号的用户ID
 */
export function getUserIdC (data) {
    return request({
        url: '/open/user/getUserIdC',
        method: 'get',
        params: data,
    })
}

/**
 * 企业
 * 营业执照对比
 */
export function fileCheck (data, id) {
    return request({
        url: '/open/ocr/fileCheck?userId=' + id,
        method: 'post',
        "Content-Type": "multipart/form-data",
        data
    })
}

/**
 * 手机号修改
 */
export function updatePhoneByUserId (data) {
    return request({
        url: '/open/user/updatePhoneByUserId',
        method: 'get',
        params: data
    })
}